'use strict';

/* exported
  menuProspectListCtrl
 */
function menuProspectListCtrl($scope, InventoryRestangular, $rootScope, user, GOOGLE_HL_LANGUAGES) {
  $scope.isUserAllowed = user.additionalScopes.includes('prospect-list-admin');
  $scope.hlLanguages = GOOGLE_HL_LANGUAGES;
  $scope.resetFilters = function () {
    $scope.temp = {
      selectedLocations: [],
    };
    $scope.filters = {
      fileName: "",
      query: "",
      locations: [],
      googleDomain: "google.com",
      geolocation: 'us',
      hostingLanguage: 'en',
      device: 'mobile'
    };
  };

  $scope.resetFilters();
  $scope.locationsList = [];

  $scope.refreshLocationList = function (search) {
    if ($scope.isUserAllowed) {
      InventoryRestangular.one('serp-api/locations').get({query: search})
        .then(function successCallback(response) {
          if (response && response.length) {
            $scope.locationsList = response;
          } else {
            $scope.locationsList = [];
          }
        }, function errorCallback(err) {
          $scope.$emit('notify', {
            type: 'error',
            title: 'Failed to fetch',
            message: `Failed to fetch locations from SerpApi, ${err.message}`
          });
        });
    }
  };

  $scope.onSubmit = function () {
    if ($scope.isUserAllowed) {
      $scope.temp.selectedLocations.forEach(function (location) {
        $scope.filters.locations.push(location.canonicalName);
      });
      InventoryRestangular.one(`/serp-api`).customPOST($scope.filters).then((data) => {
        const plainData = data.plain();
        $rootScope.$broadcast('prospectList.list', [plainData]);
      })
        .catch((err) => {
          $rootScope.$emit('notify', {type: 'error', title: 'failed to fetch', message: err.data && err.data.message});
        });
      $scope.resetFilters();
    } else {
        $scope.$emit('notify', {
          type: 'info',
          title: 'No permission',
          message: `You are not allowed to make new searches`
        });
    }
  };
}

'use strict';

/* exported
  prospectListCtrl
 */
function prospectListCtrl($scope, InventoryRestangular) {
        $scope.list = [];
        $scope.totalItems = 0;
        $scope.currentPage = 1;
        $scope.totalSearches = 0;

        const updateCurrentView = function (data, totalItems) {
            $scope.list = data;
            $scope.totalItems = totalItems;
        };

        const getProspectList = function (fileName = '', offset = 0) {
            InventoryRestangular.all(`/serp-api-sheets`).getList({fileName, offset})
              .then((data) => {
                updateCurrentView(data, data.meta.total);
              })
              .catch((err) => {
                $scope.$emit('notify', {type: 'error', title: 'failed to fetch', message: err.data && err.data.message});
              });
        };



        const getTotalSearches = function (){
          InventoryRestangular.one(`/serp-api/total-searches`).get()
            .then((data) => {
              $scope.totalSearches = (data && data.counter) || 0;
            })
            .catch((err) => {
              $scope.$emit('notify', {type: 'error', title: 'failed to fetch', message: err.data && err.data.message});
            });
        };

        getProspectList();
        getTotalSearches();

        $scope.pageChanged = function () {
            getProspectList('', ($scope.currentPage - 1) * 20);
        };

        $scope.$on('prospectList.list', function (event, data) {
            $scope.fileName = '';
            updateCurrentView(data, 1);
        });

        $scope.findFiles = function () {
            getProspectList($scope.fileName);
        };

        $scope.resetList = function () {
            $scope.fileName = '';
            getProspectList();
        };
}
